<script lang="ts" setup>
import { Atropos } from 'atropos/vue'
import { storeToRefs } from 'pinia'
import { useRoot } from '~/store'

const props = defineProps<{
  updatedAt?: Date | string
}>()

const p = ref<HTMLElement | null>(null)

const { theme } = storeToRefs(useRoot())
</script>

<template lang="pug">
Atropos(
  :rotate="true"
  :active-offset="5"
  :shadow-scale="1.05"
  :rotate-x-max="1"
)
  div.paragraph(
    ref="p"
    :style="`background-image: linear-gradient(to top right, ${theme?.hexPrimary}, ${theme?.hexPrimary}, ${theme?.hexSecondary})`"
  )
    p.content(
      v-bind="$attrs"
    )
      slot
    time(
      v-if="updatedAt"
      v-tooltip="`Copy updated at ${updatedAt}`"
      :datetime="updatedAt"
    ) {{ updatedAt }}
</template>

<style lang="sass" scoped>
.paragraph
  @apply w-full p-1.5 bg-[length:400%_400%] animate-bg-to-right-fast rounded-xl shadow-2xl relative
  .content
    @apply w-full max-w-full p-2 py-8 text-sm text-white bg-dark-900 rounded-lg max-h-[50vh] overflow-y-auto overflow-x-hidden scrollbar-secondary
  :deep(.content)
    p:not(:first-of-type)
      @apply mt-2
      span
        @apply text-secondary font-bold px-1
      a
        @apply text-secondary underline cursor-pointer ml-1
      strong
        @apply underline mx-1
  time
    @apply absolute bottom-0 right-0 py-1 px-2 bg-black border-2 border-secondary text-xs lg:text-sm font-bold text-white rounded-[50px]
</style>
